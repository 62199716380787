@tailwind base;
@tailwind components;
@tailwind utilities;
@import "styles/colors.module.scss";
@import "styles/colors.module.scss";


//@import "prismjs/themes/prism.css";
//@import "prismjs/plugins/toolbar/prism-toolbar.min.css";


// @import "~prismjs/plugins/line-numbers/prism-line-numbers.css";

@layer base {
	@font-face {
		font-family: "Inter";
		src: url(/assets/fonts/Inter-VariableFont_wght.woff2) format("woff2-variations");
	}
}

:root {
	--maia-petrol-50: #d7eff8;
	--maia-petrol-100: #c5e8f5;
	--maia-petrol-200: #a3daef;
	--maia-petrol-300: #80cde9;
	--maia-petrol-400: #5dbfe3;
	--maia-petrol-500: #3ab1dd;
	--maia-petrol-600: #239ecc;
	--maia-petrol-700: #1d83a9;
	--maia-petrol-800: #176886;
	--maia-petrol-900: #0f4356;
	--maia-petrol-950: #0b303e;
}

html {
	min-height: 100%;
	display: flex;
}

body {
	overscroll-behavior: contain;
	display: block;
	min-height: 100%;
	margin: 0;
	overflow: hidden;
}

.code-input {
	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type="number"] {
		-moz-appearance: textfield;
	}
}
// * {
// 	font-synthesis: none !important;
// }
i,
em {
	@apply italic;
}

.dark-backdrop {
	background: rgba(0, 0, 0, 0.5);
}

.hide-scroll::-webkit-scrollbar {
	display: none;
}
.hide-scroll {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

input[type="checkbox"] {
	@apply cursor-pointer;
	@apply accent-maia-petrol-800;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition:
		background-color 0s 600000s,
		color 0s 600000s !important;
}

.custom-scroll::-webkit-scrollbar {
	@apply w-3;
}

.custom-scroll::-webkit-scrollbar-track {
	@apply bg-transparent;
	@apply border;
	@apply border-transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
	@apply rounded-full;
	border: 0.1875rem solid transparent;
	background-clip: content-box;
	@apply bg-maia-petrol-800;
}

.loader {
	@apply bg-white;
	@apply absolute;
	@apply w-3/4;
	//@apply max-w-lg;
	@apply flex;
	@apply flex-col;
	@apply justify-center;
	@apply items-center;
	@apply top-1/2;
	@apply left-1/2;
	@apply -translate-x-1/2;
	@apply -translate-y-1/2;
	img {
		@apply w-full;
		@apply max-w-none;
	}
}

#CookiebotWidget {
	display: none;
}
#CookiebotWidget.block {
	display: block;
}

.cdk-global-scrollblock {
	overflow-y: auto !important;
}

.cdk-virtual-scroll-viewport {
	mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 12px, black 12px);
	mask-size: 100% 50000px;
	mask-position: left bottom;
	transition: mask-position 0.3s;
	&:hover {
		mask-position: left top;
	}
	&::-webkit-scrollbar {
		@apply w-3;
	}
	&::-webkit-scrollbar-track {
		@apply bg-transparent;
	}
	&::-webkit-scrollbar-thumb {
		@apply bg-gray-400;
		@apply rounded-md;
		border: 3px solid white;
	}
	&::-webkit-scrollbar-thumb:hover {
		@apply bg-maia-petrol-900;
	}
}

input[type="checkbox"] + maia-icons {
	display: none;
}

input[type="checkbox"]:checked + maia-icons {
	display: inline-flex;
}

.my-custom-tooltip .mdc-tooltip__surface {
	max-width: unset !important;
}

input::-ms-reveal,
input::-ms-clear {
	display: none;
}